<script>
import LayoutContent from "./LayoutContent";
import {bus} from "../../router/bus";

export default {
  name: 'DefaultLayout',

  components: {
    LayoutContent
  },
  
  data() {
    return {
      backgroundColor: '#FFFFFF',
    }
  },
  
  mounted() {
    bus.$on("showRateList", (data) => {
      if(data === true) {
        this.backgroundColor = 'var(--grey-10)'
      } else {
        this.backgroundColor = '#FFFFFF'
      }
    });
  }
};
</script>

<template>
  <div
    class="default-layout"
    :style="{ background: backgroundColor }"
  >
    <div class="g-container">
      <LayoutContent
        :polygon="true"
        :contactFooter="true"
        class="default-layout__header"
      />
      
      <slot class="default-layout__content"/>
    </div>
  </div >
</template>

<style lang="scss" scoped>

.default-layout {
  width: 100%;
  margin: 0 auto 0;

  @media (max-width: $tablet-width) {
    background: #FFFFFF !important;
  }
  
  &__header {
    z-index: 1;
  }

  &__content {
    z-index: 10;
    position: absolute;
    width: 100%;
  }
}
</style>
