<script>
import { bus } from "@/router/bus";

export default {
  name: "MainHeader.vue",

  props: {
    type: {
      type: String,
      required: false,
      default: () => "light",
      validator: (value) => ["light", "dark" ].includes(value),
    }
  },
  
  data: () => ({
    isSetting: 0,
    authStatus: !!localStorage.getItem('is_auth')
  }),
  
  computed: {
    isAuth() {
      return this.authStatus;
    },
    
    isToken() {
      return !!this.$_getsetting('token');
    },
  },

  mounted() {
    if (this.isAuth === true && this.isToken === true) {
      setTimeout(() => {
        this.checkKey()
      }, 2000);
    }
    
    this.checkIsSiteAvailable()
    
    if (!this.isAuth && !this.$_getsetting('client_id')) {
      this.$_setsetting('client_id', -99)
    }

    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting('is_auth')
    })
  },

  methods:{
    showLoginForm(data) {
      bus.$emit("showloginform", data)
    },

    gotoMain(){
      if (this.$route.name !== "MainPage") {
        this.$router.push("/")
      } else {
        bus.$emit('hide_rate')
      }
    },

    gotoProfile(){
      if (this.$route.name !== "Profile") {
        this.$router.push("/Profile")
      }
    },
  
    reachYMGoal(name) {
      this.$metrika.reachGoal(name)
    },
    
    checkIsSiteAvailable() {
      this.$postapi(this.$address + this.$setters.getSettingByName.uri, {
        method: this.$setters.getSettingByName.name,
        setting_name: "is_under_construction"
      }).then(data => {
        if (data.error == 0) {
          this.isSetting = JSON.parse(data.message)
          if (this.isSetting.is_under_construction == 1){
            this.$router.push("/ErrorPage")
          }
        }
      });
    },
  
    checkKey() {
      this.$postapi(this.$address + this.$setters.check.uri, {
        method: this.$setters.check.name,
        token: this.$_getsetting('token'),
      }).then(data => {
        if (data) {
          if (data.error == 0) {
            console.log(data.message)
          } else {
            this.$_removesetting('token')
            this.$_removesetting('user_id')
            this.$_removesetting('login')
            this.$_removesetting('email')
            this.$_removesetting('is_auth')
            this.$_removesetting('client_id')
            this.$_removesetting('fio')
            this.$_removesetting('clnt_name')
            this.$_removesetting('manager_email')
            this.$_removesetting('is_show_bm_messages')
            this.$_removesetting('manager_name')
            this.$_removesetting('manager_phone')
            this.$_removesetting('place_from')
            this.$_removesetting('place_to')
            this.$_removesetting('unit_code')
            this.$_removesetting('on_date')
            this.$forceUpdate();
            setTimeout(() => {
              bus.$emit("show_notify", {
                color: "warn",
                duration: 4500,
                notifytext: "Время сессии истекло по причине отсутствия активности на сайте в течение 12 часов. Просим пройти авторизацию повторно."
              });
            }, 400);
            this.$router.push('/')
            bus.$emit('auth-update');
          }
        }
      });
    },
  }
}
</script>

<template>
  <div
    class="main-header"
    :class="type === 'light' ? 'main-header-light' : 'main-header-dark'"
  >
    <div
      class="main-header__logo g-col-md-2 g-col-lg-2 g-col-sm-1 g-col-xs-1"
      @click="gotoMain"
    >
      <img src="../../assets/img/vedexx-logo/logo_light.svg" alt="vedexx-logo"/>
    </div>

    <div class="main-header__login-btns">

      <div class="main-header__login-btns-contact_links">
        <a class="main-header__login-btns-contact_link" href="tel:+78005550999" @click="reachYMGoal('SPBphonetap')">+7(800) 555-09-99</a>
        <br/>
        <a class="main-header__login-btns-contact_link" href="mailto:spb@vedexx.com" @click="reachYMGoal('Email')">spb@vedexx.com</a>
      </div>

      <v-btn
        class="main-header__login-btns-contact_links"
        fab
        dark
        small
        depressed
        rounded
        color="white"
        href="https://t.me/vedexx_news"
        target="_blank"
        @click="reachYMGoal('TelegramChannel')"
      >
        <svg class="mr-1 mt-1" xmlns="http://www.w3.org/2000/svg" width="17" height="22" viewBox="0 0 17 22"
             fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.36432 7.30001C7.75566 4.95863 11.3448 3.40284 13.147 2.64805C18.2765 0.506922 19.3547 0.13723 20.0479 0.121826C20.202 0.121826 20.5408 0.152634 20.7719 0.33748C20.9567 0.491518 21.003 0.691769 21.0338 0.845807C21.0646 0.999845 21.0954 1.32333 21.0646 1.56979C20.7873 4.49651 19.5858 11.5977 18.9697 14.8633C18.7078 16.2496 18.1995 16.7118 17.7065 16.758C16.6283 16.8504 15.8119 16.0494 14.7798 15.3716C13.147 14.3088 12.2382 13.6464 10.6516 12.5989C8.81853 11.3974 10.0046 10.7351 11.0521 9.6568C11.3293 9.37953 16.0583 5.06646 16.1508 4.68136C16.1662 4.63515 16.1662 4.4503 16.0583 4.35788C15.9505 4.26546 15.7965 4.29626 15.6732 4.32707C15.5038 4.35788 12.9159 6.08311 7.87889 9.48736C7.13951 9.99568 6.47714 10.2421 5.87639 10.2267C5.21403 10.2113 3.95092 9.85705 2.99588 9.54897C1.84059 9.17928 0.916361 8.97903 0.99338 8.33207C1.03959 7.99318 1.50171 7.6543 2.36432 7.30001Z"
            fill="var(--main-bg-color)"/>
        </svg>
      </v-btn>

      <div v-if="!isAuth">
        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="showLoginForm('login')"
        >
          <v-icon dark class="mr-2">
            mdi-account
          </v-icon>
          Войти
        </v-btn>
    
        <div class="main-header__login-btns-divider"></div>
    
        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="showLoginForm('signup')"
        >
          Регистрация
        </v-btn>
      </div>
      
      <div v-else>
        <v-btn
          class="main-header__login-btns-btn"
          text
          dark
          color="white"
          @click="gotoProfile()"
        >
          <v-icon class="mr-2" dark>
            mdi-account
          </v-icon>
          {{$_getsetting('login')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-header-light {
  height: 84px;
  line-height: 84px;
  
  @media (max-width: 743px) {
    display: none;
  }
}

.main-header-dark {
  height: 72px;
  line-height: 68px;
  
  @media (max-width: 743px) {
    display: none;
  }
}

.main-header {
  display: flex;
  width: 100%;
  margin: auto;
  padding: 0;
  align-items: center;
  align-content: center;
  
  @media (max-width: 700px) {
    display: none;
  }

  &__logo {
    position: relative;
    float: left;
    cursor: pointer;

    > img {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 100%;
      height: auto;
      
      @media (min-width: 1919px) {
        position: relative;
        width: 75%;
      }
      
      @media (max-width: 600px) {
        position: relative;
        width: 100%;
      }
    }
    
    &-mobile-text {
      text-transform: uppercase;
      color: var(--main-bg-color);
      font-weight: 500;
      font-family: 'Troverst', sans-serif;
      font-style: normal;
      display: inline;
      align-items: center;
      position: absolute;
      font-size: 25px;
      line-height: 28px;
      margin-left: -110px;
      top: 4px;
      margin-top: 20px;
      letter-spacing: 0.01em;
  
      @media (min-width: 601px) {
        display: block;
      }
    }
  }
  
  &__login-btns {
    float: right;
    position: relative;
    display: flex;
    flex-direction: row;

    &-contact_links {
      color: white;
      text-align: end;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      line-height: 12px;
      top: 0;
      margin: auto $size-12;
      
      @media (min-width: 1919px) {
        font-size: $size-16;
        margin: auto $size-16;
      }
      
      @media (max-width: 600px) {
        display: none;
      }
    }

    &-btn {
      text-transform: none;

      @include font-description-semibold-1;
      font-weight: 700;

      @media (max-width: 600px) {
        display: none;
      }
    }

    &-mobile {
      display: none;
      margin-top: 10px;

      @media (max-width: 600px) {
        display: inline-block;
      }
    }

    &-divider {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0;
      width: 1.5px;
      height: 30px;
      background: #FFFFFF;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}

a {
  color: white !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.main-header__login-btns-contact_link{
    @include font-body-2;
    line-height: 14px;
}

</style>
