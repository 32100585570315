<script>
import MainHeader from "./MainHeader";
import MainHeaderMobile from "./MainHeaderMobile";
import LoginForm from "../common/LoginForm";
import Modal from "../common/Modal";
import ContactFooter from "./ContactFooter";

import { bus } from "@/router/bus";
export default {
  name: 'LayoutContent',
  
  components: {
    ContactFooter,
    MainHeader,
    MainHeaderMobile,
    LoginForm,
    Modal,
  },

  props: {
    polygon: { type: Boolean, default: false },

    headerType: {
      type: String,
      required: false,
      default: () => "light",
      validator: (value) => ["light", "dark" ].includes(value),
    },
    
    contactFooter: { type: Boolean, default: false },
  },

  data: () => ({
    popupVisible: false,
    goto: (this != undefined) ? this.$_getsetting('routerState') : "",
    menuOptions: [],
    formType: null,

    showFooter: true,
  }),

  created () {
    bus.$on("show_notify", (args) => {
      this.showNotify(args)
    });
  },

  mounted() {
    bus.$on("showloginform", (data) => {
      this.popupVisible = true
      this.formType = data
    });

    bus.$on("showRateList", (data) => {
      this.showFooter = !data
    })
  },

  methods: {
    showNotify(args){
      if (args.notifytext != null) {
        this.$notify({
          title: 'Внимание',
          text: args.notifytext,
          type: args.color,
          duration: args.duration ? args.duration : 3000,
          ignoreDuplicates: true,
        });
      }
    },
  }
};
</script>

<template>
  <div class="layout-content" :class="headerType === 'light' ? 'layout-content-light' : ''">
    <div v-if="polygon === true" class="layout-content__layout-top"></div>
    
    <MainHeader
      :type="headerType"
      :class="headerType === 'light' ? 'layout-content__header-light' : 'layout-content__header-dark'"
      class="layout-content__header"
    />
    
     <MainHeaderMobile
      :type="headerType"
      class="layout-content__header-mobile"
    />
    
    <Modal :showModal="popupVisible">
      <LoginForm
        class="layout-content__login-form"
        :type="formType"
        @close="popupVisible = false"
      />
    </Modal>
    
    <ContactFooter
      id="contact"
      ref="contact"
      class="layout-content__footer"
      v-if="contactFooter && showFooter"
    />
  </div>
</template>

<style lang="scss" scoped>
.layout-content {
  background: none;
  
  &-light {
    margin-top: 80px;
    padding-top: 20px;
    
    @media (max-width: $mobile-width) {
      margin-top: 0px;
    }
  }
  
  &__layout-top {
    height: 620px;
    width: 100%;
    left: 0;
    top: 100px;
    margin-top: -10px;
    background-color: var(--main-bg-color);
    position: absolute;
    display: block;
  
    @media (max-width: $tablet-width) {
      height: 560px;
      top: 50px;
    }
    
    @media (max-width: $mobile-width) {
      height: 700px;
      margin-top: 10px;
    }
  }

  &__header {
    display: inline-block;
    margin: auto;
    position: fixed;
    z-index: 110;
    background-color: var(--grey-70);
    top: 0;
    left: 0;
    padding: 0 64px;

    @media print {
      display: none !important;
    }

    @media (min-width: 1920px) {
      padding: 0 calc((100% - 1696px) / 2);
    }
    
    @media (max-width: $tablet-width) {
      display: none;
    }
    
    &-light {
      height: 90px;
    }
    
    &-dark {
      height: 70px;
    }
  }
  
  &__header-mobile {
    display: none;
    position: absolute;
    margin: 20px 0 60px 0;

    @media print {
      display: none !important;
    }

    @media (max-width: $tablet-width) {
      display: block;
    }
  }

  &__login-form {
    width: 60%;
    position: absolute;
    
    @media (max-width: $tablet-width) {
      width: 80%;
    }
    
    @media (max-width: $mobile-width) {
      width: 100%;
      height: 100%;
    }
  }

  &__msg-btn {
    position: fixed;
    bottom: 30px;
    right: 7%;
    z-index: 999999;
  }

  &__polygon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -10;

    @media (max-width: $mobile-width) {
      display: none;
    }
  }
  
  &__footer {
    position: absolute;
    //width: 100%;
    //left: 0;
    bottom: -380px;
    
    @media (max-width: $tablet-width) {
      bottom: -20px;
      height: 0px;
    }
  }
}
</style>
