<script>
import { bus } from "../../router/bus";
import ContactForm from "../common/ContactForm";

export default {
  name: "MainFooter.vue",
  
  components: {
    ContactForm
  },
  
  data: () => ({
    user_email: null,
    message: null,
    subject: null,
    contactInfo: [
      {
        city: "Санкт-Петербург",
        address: "196247, г. Санкт-Петербург, пл. Конституции д. 3,\n" +
          "корпус 2, литер А, ч.п./офис 70Н/1",
        phone: "+7(800)555-09-99",
        email: "spb@vedexx.com",
      },
      {
        city: "Калининград",
        address: "236006, г. Калининград проспект Ленинский  д. 30А, помещение 7, помещение 8",
        phone: "+7(981)704-43-09",
        email: "kgd@vedexx.com",
      },
      {
        city: "Владивосток",
        address: "690001, Г. Владивосток, ул. Светланская, д. 83, оф. 301",
        phone: "",
        email: "vvo@vedexx.com",
      },
      {
        city: "Находка",
        address: "Врангель м-н, Находка, Приморский край Внутрипортовая, 19 офис 3\n" +
          "692941",
        phone: "",
        email: "nhd@vedexx.com",
      }
    ]
  }),
  
  mounted() {
    if (this.$_getsetting('email')) {
      this.user_email = this.$_getsetting('email')
    }
  },
  
  methods: {
    sendContactForm() {
      this.$postapi(this.$address + this.$getters.setFeedBack.uri, {
        method: this.$getters.setFeedBack.name,
        bcc: '',
        cc: '',
        to: this.$_getsetting('manager_email') ? this.$_getsetting('manager_email') : 'help@vedexx.com',
        subject: this.subject,
        type: 'email',
        body: 'от: ' + this.$_getsetting('clnt_name') + ' почта: <' + this.user_email + '> текст: ' + this.message,
        atachments: [],
      }).then(data => {
        if (data.error == 0) {
          this.clearForm()
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
          this.clearForm()
        }
      });
    },
  
    clearForm() {
      this.message = ''
    }
  }
}
</script>

<template>
  <div
    class="main-footer g-row"
  >
    <div class="main-footer__section1 g-col-lg-8 g-col-md-8 g-col-sm-8 g-col-xs-2">
      <div class="main-footer__title">Остались вопросы? Свяжитесь с&nbsp;нами</div>
  
      <div
        class="main-footer__contacts"
      >
        <div
          class="main-footer__contacts__block"
          v-for="(city, index) in contactInfo"
          :key="index"
        >
          <div class="main-footer__contacts__block-name">{{city.city}}</div>
      
          <div class="main-footer__contacts__block-data" v-if="city.phone !=''">
            <v-icon
              size="14"
              color="var(--main-bg-color)"
            >
              mdi-phone
            </v-icon>
            <a :href="'tel:' + city.phone" @click="reachYMGoal(city.phoneGoalName)">
              {{city.phone}}
            </a>
          </div>
      
          <div class="main-footer__contacts__block-data">
            <v-icon
              size="14"
              color="var(--main-bg-color)"
            >mdi-email
            </v-icon>
            <a :href="'mailto:' + city.email" @click="reachYMGoal('Email')">
              {{city.email}}
            </a>
          </div>
          <div class="main-footer__contacts__block-data main-footer__contacts__block-address">{{city.address}}</div>
        </div>
      </div>
    </div>
    
    <div class="main-footer__section2 g-col-lg-4 g-col-md-4 g-col-sm-8 g-col-xs-2">
      <ContactForm class="main-footer__form"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

.main-footer {
  margin: auto;
  width: calc(100% - 64px) !important;
  padding: 0;
  
  @media (min-width: 1920px) {
    max-width: 1696px !important;
  }
  
  @media (max-width: 1919px) {
    width: calc(100% - 64px * 2) !important;
  }
  
   @media (max-width: $tablet-width) {
    width: calc(100% - 32px * 2) !important;
  }
  
   @media (max-width: 743px) {
    width: calc(100% - 16px * 2) !important;
  }
  
  
  &__section1 {
  
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
  
  &__section2 {
  
    @media (max-width: $mobile-width) {
      width: 100%;
      margin: 0;
      //padding: 0;
    }
  }
  
  &__title {
    @include font-headline-2;

    font-size: 24px;
    line-height: 27px;
    color: black;
    margin: 40px 0 20px 0;

  
    @media (max-width: 600px) {
      font-size: 20px;
      margin: 10px 0;
    }
  }
  
  &__form-title {
    display: none;
    font-size: 20px;
    margin: 30px 0 -5px;
    font-weight: 700;
    width: 100%;
  
    @media (max-width: $mobile-width) {
      display: block;
    }
  }
  
  &__contacts {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    display: grid;
    width: 100%;
    position: relative;
    flex-direction: row;
    align-items: self-start;
    align-content: center;
    
    &__block {
      position: relative;
      
      &-name {
        @include font-body-1;
        font-weight: 600;
        font-size: 18px;
  
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
      
      &-data {
        @include font-body-2;

        line-height: 24px;
        font-size: 16px;
        margin: 4px 0;
  
        @media (max-width: $mobile-width) {
          font-size: 14px;
          margin: 2px 0;
        }
      }
      
      &-address {
        @media (max-width: $mobile-width) {
         display: none;
        }
      }
    }
  }
  
  &__form {
    margin-top: 30px;
    margin-bottom: 50px;
  
    @media (max-width: $mobile-width) {
      width: 100%;
      max-width: 100%;
      margin: 20px 0 0;
      padding: 0;
    }
    
    &-input {
      margin-bottom: -10px;
  
      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
    
    &-btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.00892857em;
      text-transform: lowercase;
  
      @media (max-width: $mobile-width) {
        width: 100% !important;
        //margin-right: 40px;
      }
    }
    
    &-link {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--bg-dark);
    }
  }
  
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

a {
  //color: black !important;
  text-decoration: underline;
  color: var(--bg-dark) !important;
  //margin-left: 4px;
  
  &:hover {
    text-decoration: underline;
  }
}

</style>
